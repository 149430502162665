import {SHIFT_AND_CLOCKING_ROUTE_NAMES} from '@/features/shift-and-clocking/constants';
import {afterEachHandler, beforeEachHandler, commonRoutes, onError} from '@jetCommon/router';
import {api} from '@/api';
import {createRouter, createWebHashHistory} from 'vue-router';
import {scrollBehavior} from '@jetCommon/router';
import {useSpaStore} from '@/stores/spa';
import settings from '@/env';

const routes = [
    ...commonRoutes,
    {
        path: '/registrazione/:specialLinkCode',
        component: () => import('@/views/FirstAccessView.vue'),
        meta: {requiresAuth: false},
        name: 'firstAccessView',
    },
    {
        path: '/completa-assunzione/:hiringUUID',
        component: () => import('@/features/hiring/views/HiringContractView.vue'),
        meta: {requiresAuth: false},
        name: 'hiringContractView',
    },
    {
        path: '/completa-assunzione/link-scaduto',
        component: () => import('@/features/hiring/views/HiringExpiredLinkView.vue'),
        meta: {requiresAuth: false},
        name: 'hiringContractExpiredView',
    },
    {
        path: `/:${settings.JET_ROUTE_ENTITY_NAME}?`,
        component: () => import('@/layouts/SidebarLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/DashboardView.vue'),
                name: 'dashboardView',
            },
            {
                path: 'assenze',
                component: () => import('@/views/AbsenceRequestsView.vue'),
                name: 'absenceRequestsView',
            },
            {
                path: 'timbrature',
                component: () => import('@/features/clocking/views/ClockingsView.vue'),
                name: SHIFT_AND_CLOCKING_ROUTE_NAMES.CLOCKING,
            },
            {
                path: 'turni',
                component: () => import('@/features/shift/views/ShiftsView.vue'),
                name: SHIFT_AND_CLOCKING_ROUTE_NAMES.SHIFT,
            },
            {
                path: 'turni-timbrature',
                component: () => import('@/features/clocking/views/ClockingsView.vue'),
                name: SHIFT_AND_CLOCKING_ROUTE_NAMES.SHIFT_AND_CLOCKING,
            },
            {
                path: 'assenze-calendario',
                component: () => import('@/views/AbsenceCalendarView.vue'),
                name: 'absenceCalendarView',
            },
            {
                path: 'rimborsi-spese',
                component: () => import('@/features/expense/views/ExpensesView.vue'),
                name: 'expensesView',
            },
            {
                path: 'documenti',
                component: () => import('@/views/DocumentsView.vue'),
                name: 'documentsView',
            },
            {
                path: 'impostazioni',
                component: () => import('@/views/SettingsView.vue'),
                name: 'settingsView',
            },
            {
                path: 'team',
                component: () => import('@/views/ManagerTeamView.vue'),
                name: 'managerTeamView',
            },
            {
                path: 'team/:teamEmployeeId(\\d+)',
                component: () => import('@/views/ManagerTeamEmployeeDetailView.vue'),
                name: 'managerTeamEmployeeDetailView',
            },
            {
                path: 'team-esterni/:teamContractorId(\\d+)',
                component: () => import('@/views/ManagerTeamContractorDetailView.vue'),
                name: 'managerTeamContractorDetailView',
            },
            {
                path: 'approvazioni-assenze',
                component: () => import('@/views/ManagerAbsencesRequestsView.vue'),
                name: 'managerAbsencesView',
            },
            {
                path: 'gestione-timbrature/:period/:tab(tab-tutte|tab-da-risolvere|tab-risolte|tab-contatori-periodici)',
                component: () => import('@/features/clocking/views/ManagerClockingsView.vue'),
                name: 'managerClockingsViewWithTab',
            },
            {
                path: 'gestione-timbrature',
                redirect: to => ({path: `${to.path}/corrente/tab-da-risolvere`, replace: true}),
                name: 'managerClockingsView',
            },
            {
                path: 'calendario-turni',
                component: () => import('@/features/shift/views/ShiftCalendarManagerView.vue'),
                name: 'shiftCalendarView',
            },
            {
                path: 'calendario-turni/preset-salvati',
                component: () => import('@/features/shift/views/ShiftPresetView.vue'),
                name: 'shiftPresetView',
            },
            {
                path: 'approvazioni-spese',
                component: () => import('@/features/expense/views/ManagerExpensesView.vue'),
                name: 'managerExpensesView',
            },
            {
                path: 'formazione-obbligatoria-sicurezza',
                component: () => import('@/features/safety-and-health/TrainingCourseView.vue'),
                name: 'trainingCourseView',
            },
            {
                path: ':pathMatch(.*)*',
                redirect: () => '/404',
            },
        ],
    },
];

const router = createRouter({
    scrollBehavior,
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(
    async (to, from) => await beforeEachHandler(to, from, api, useSpaStore, settings.JET_ROUTE_ENTITY_NAME),
);

router.afterEach(afterEachHandler);

router.onError(onError);

export default router;
