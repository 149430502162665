import EmployeeApiCommon from '@jetCommon/api/employee';

export default class EmployeeApi extends EmployeeApiCommon {
    retrieveNotificationSettings(employeeId) {
        return this.retrieve(`${employeeId}/notification_settings`);
    }

    updateNotificationSettings(employeeId, data) {
        return this.patch(`${employeeId}/notification_settings`, data);
    }

    getCourseStatus(employeeId) {
        return this.retrieve(`${employeeId}/get_course_status`);
    }
}
