import {ApiBase} from '@jetCommon/api/base';

export default class HiringDocumentApi extends ApiBase {
    static resourceName = 'hiring_documents';

    publicPdfPreviewUrl(id, uuid) {
        return this.getApiUrl(`${id}/public_pdf_preview/?uuid=${uuid}`, true);
    }

    publicUploadFile(data) {
        return this.apiPostForm('public_upload_file/', data);
    }
}
