import {usePushNotificationsStore} from '@jetCommon/stores/push-notifications';

export function usePushNotifications() {
    const store = usePushNotificationsStore();

    const handleReceivedToken = e => {
        store.token = e.detail;
    };

    const registerListenForToken = () => addEventListener('received-push-registration-token', handleReceivedToken);

    const askPushPermissionIOS = () => window?.webkit?.messageHandlers?.pushPermissionRequest?.postMessage({});
    const askPushPermissionAndroid = () => {
        if (typeof Android !== 'undefined') {
            // eslint-disable-line no-undef
            Android?.requestPushPermission(); // eslint-disable-line no-undef
        }
    };

    const askPushPermission = () => {
        askPushPermissionIOS();
        askPushPermissionAndroid();
    };

    const handleReceivedNotification = e => {
        if (e.detail.url) {
            nextTick(() => {
                window.location = e.detail.url;
            });
        }
    };

    const registerListenForNotification = () => addEventListener('push-notification', handleReceivedNotification);

    return {
        askPushPermission,
        registerListenForToken,
        registerListenForNotification,
    };
}
