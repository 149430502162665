import {dailyClockingSurrogateKey} from '@jetCommon/helpers/clocking';
import ClockingsApiCommon from '@jetCommon/api/clocking';

export default class ClockingApi extends ClockingsApiCommon {
    static resourceName = 'daily_clockings';

    nextClocking(companyId) {
        return this.apiGet('next_clocking/', {params: {company: companyId}});
    }

    addClocking(companyId, kind, coordinates) {
        return this.apiPost('add_clocking/', {company: companyId, kind, coordinates});
    }

    today(companyId) {
        return this.apiGet('today/', {params: {company: companyId}});
    }

    /**
     * Updates the notes for a specific company on a given date.
     *
     * @param {string} employeeId - The ID of the employee.
     * @param {string} companyId - The ID of the company.
     * @param {string} date - The date for which the note is being updated, formatted as YYYY-MM-DD or today.
     * @param {string} note - The note to be updated.
     * @returns {Promise} - A promise that resolves when the note is updated.
     */
    updateNotes(employeeId, companyId, date, note) {
        return this.apiPatch(`${dailyClockingSurrogateKey(employeeId, date)}/`, {company: companyId, note});
    }

    deleteLastClocking(companyId) {
        return this.apiDelete('last_clocking/', {params: {company: companyId}});
    }

    areClockingsEnabled(companyId) {
        return this.apiGet('are_clockings_enabled/', {params: {company: companyId}});
    }
}
