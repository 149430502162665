import ManagerApiCommon from '@jetCommon/api/manager';

export default class ManagerApi extends ManagerApiCommon {
    retrieveNotificationSettings(managerId) {
        return this.retrieve(`${managerId}/notification_settings`);
    }

    updateNotificationSettings(managerId, data) {
        return this.patch(`${managerId}/notification_settings`, data);
    }
}
